.buildInfo {
  display: flex;
  gap: 8px;
  color: var(--gray-800);
  overflow: hidden;
  margin-left: 20px;
  @media (max-width: 1200px) {
    font-size: 12px;
    b {
      font-size: 12px;
    }
  }
  @media (max-width: 820px) {
    display: none;
  }
}
