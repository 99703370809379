.formContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 3px;

  &__errorBanner {
    margin-bottom: 24px;
  }

  & > h3 {
    padding-top: 24px;
    padding-bottom: 8px;
    &:first-of-type {
      padding-top: 0px;
    }
  }

  :global(.cmp-input) > input {
    display: flex;
    margin-top: 0;
    height: 44px;
  }

  :global(.just-select-container) > input {
    height: 42px;
  }
  :global(.just-select-container) {
    &:global(.multiple) > input {
      height: 34px;
    }
  }

  :global(input) {
    padding: 12px 16px;
    border-radius: 6px;
    line-height: 20px;
  }

  :global(.cmp-input__error-box) {
    padding-top: 8px;
  }
}
