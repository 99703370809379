.caila-header {
  background-color: white;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid var(--gray-100);
  grid-area: header;

  &__button_immers {
    background-color: #0066ff;
  }

  &__button_immers_outline {
    color: #333333;
  }

  &__documentation_link {
    .justui__icon-wrapper {
      display: flex;
    }
    border-radius: 6px;
    transition: background-color 0.15s;
    padding: 10px 12px;
    margin-left: -5px;
    margin-right: -5px;
    &:hover {
      background-color: var(--primary-200);
    }
  }
  &__container {
    width: 100%;
    // max-width: 1280px;
    height: 56px;
    padding: 10px 2.6rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575px) {
      padding: 15px;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }
  &__btn-block {
    display: flex;
    margin-left: 2rem;
    gap: 8px;
    white-space: nowrap;
    align-items: center;
  }
  &__block {
    display: flex;
    gap: 48px;
    white-space: nowrap;
    align-items: center;
  }
  &__block_immers {
    margin-left: 135px;
  }
  .dropdown-menu__container {
    margin-left: 12px;
  }
  & .dropdown-menu__account {
    min-width: 126px;
    transform: translate(calc(-50% + 25px), 41px) !important;
    z-index: 750;
    & .dropdown-item {
      padding: 8px 12px;
      a {
        color: inherit !important;
      }
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
  }
  & .dropdown-menu__toggle {
    width: auto;
    height: auto;
    margin: 3px;
  }
}

.headerNavigationTabs {
  width: fit-content;
  flex-wrap: nowrap;
  margin: 0 !important;
  gap: 8px;
  &.justui_tabs.nav {
    border-bottom: none;
    height: min-content;
  }
  > .justui__tab.nav-item {
    border-bottom: none !important;
    transition: background-color 0.15s;
    outline-color: none !important;
    border-radius: 6px;
    outline-width: 1px !important;
    color: var(--primary-800) !important;
    a {
      color: var(--primary);
      line-height: 20px;
    }
    &.active {
      border-bottom: none !important;
      background-color: var(--primary-200);
      a {
        color: inherit;
      }
    }
  }
  &_immers {
    @extend .headerNavigationTabs;
    > .justui__tab.nav-item {
      color: #333333 !important;
      a {
        color: #333333;
        line-height: 20px;
      }
      &.active {
        border-bottom: none !important;
        background-color: #0066ff;
        a {
          color: white;
        }
      }
    }
  }
}
