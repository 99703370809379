.error-info-banner {
  display: flex;
  background-color: var(--danger);
  color: var(--white);
  gap: 8px;
  padding: 10px;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  white-space: pre-wrap;
  p {
    width: 100%;

    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    overflow-y: auto;
    -webkit-box-orient: vertical;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 6px;
      min-height: 60px;
      &:hover {
        background-color: transparentize(white, 0.2);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
