.progressBar {
  overflow: hidden;
  min-height: 10px;

  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: var(--indigo-200);

  &__line {
    height: 100%;
    min-height: inherit;
    background-color: var(--indigo-400);
    width: 0;
    border-radius: inherit;
    transition: width 1.5s ease-in-out;
  }

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: var(--indigo-800);
  }
}
