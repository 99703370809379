.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px;
}

.gap-9 {
  gap: 9px;
}

.gap-10 {
  gap: 10px;
}

.gap-11 {
  gap: 11px;
}

.gap-12 {
  gap: 12px;
}

.gap-13 {
  gap: 13px;
}

.gap-14 {
  gap: 14px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-17 {
  gap: 17px;
}

.gap-18 {
  gap: 18px;
}

.gap-19 {
  gap: 19px;
}

.gap-20 {
  gap: 20px;
}
