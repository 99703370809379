.notification {
  display: flex;
  position: relative;
  padding: 4px 40px 4px 12px;
  font-size: 12px;
  flex-direction: column;
  box-sizing: border-box;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-600);

  &__title {
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    h4 {
      * {
        line-height: inherit;
      }
    }
    :global(.justui__icon-wrapper) {
      width: 12px;
      height: 12px;
    }
  }
  &__statuses {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-left: 20px;
  }

  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-800);
  }
}
