.banner {
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  button {
    padding: 4px 9px;
    line-height: 20px;
    font-size: 12px;
  }
  &-danger {
    @extend .banner;
    background-color: var(--red-100);
  }
  &-warning {
    @extend .banner;
    background-color: var(--yellow-100);
  }
  &-info {
    @extend .banner;
    background-color: var(--blue-300);
  }
}
