.task {
  display: flex;
  position: relative;
  padding: 4px 40px 4px 12px;
  font-size: 12px;
  flex-direction: column;
  box-sizing: border-box;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-600);

  &__title {
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    h4 {
      * {
        line-height: inherit;
      }
    }
    :global(.justui__icon-wrapper) {
      width: 12px;
      height: 12px;
    }
  }
  &__statuses {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-left: 20px;
  }

  &__openIconWrap {
    position: absolute !important;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 25px;
    cursor: pointer;
  }
  &__openIcon {
    position: absolute !important;

    bottom: 6px;
    right: 12px;

    display: flex;
    padding: 0;
    margin: 0;
    color: var(--gray-600);
    transition: transform 0.15s;

    &::before {
      display: none;
    }

    :global(.justui__icon-wrapper) {
      display: flex;
    }
  }
  &__closeIcon {
    position: absolute !important;
    padding: 0;
    margin: 0;

    display: flex;
    top: 6px;
    right: 12px;
    color: var(--gray-600);

    &::before {
      display: none;
    }

    &:global(.button-cmp.disabled) {
      background-color: white;
      svg {
        color: var(--gray-300) !important;
      }
    }

    :global(.justui__icon-wrapper) {
      display: flex;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-800);
  }
  &__stepInfo {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__progressBar {
    max-width: 60px;
    max-height: 4px !important;
    min-height: 4px !important;
    background-color: var(--gray-200);
  }
  &__progressBarLine {
    background-color: var(--primary-600);
  }
}
