@use 'scss/mixins/custom';

.noContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__message {
    align-self: center;
  }
  &__img {
    align-self: center;
  }
}
