.popoverWithTabs {
  &__bttn {
    margin: 0;
    &::before {
      display: none;
    }
  }
  &__popover {
    :global(.justui_popover-header) {
      border-bottom: none;
    }
    :global(.justui_popover-body) {
      padding: 0;
    }
    :global(.popover) {
      will-change: auto !important;
      width: 100%;
      max-width: 400px;
    }
  }
  &__container {
    max-height: 600px;
    overflow-y: auto;
    padding-bottom: 6px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a6abaf;
      border-radius: 6px;
      min-height: 60px;
      &:hover {
        background-color: transparentize(#a6abaf, 0.2);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__tabs {
    border-bottom: 1px solid var(--gray-300);
    padding: 0 12px 12px;

    :global(.justui_tabs) {
      &:global(.nav) {
        :global(.nav-item) {
          background-color: transparent !important;
          margin-right: 4px;
          border-radius: 4px !important;

          &:global(.active) {
            border-radius: 4px;
            border-bottom: 1px solid transparent !important;
          }

          :global(.nav-link) {
            padding: 2px 04px;
            border: none;
            font-size: 12px;
            color: var(--blue-800);
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            border-radius: 4px;

            &:hover {
              background-color: var(--gray-200);
              border-radius: 4px;
            }

            &:global(.active) {
              background-color: var(--primary-200);
              color: var(--blue-800);
            }
          }
        }
      }
    }
  }
}
